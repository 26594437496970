import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value: [],
    loading: true
}

export const datacfdiSlice = createSlice({
    name: 'datacfdi',
    initialState,
    reducers: {
        set: (state, action) => {
            state.value = action.payload
        },
        add: (state, action) => {
            state.value = [...state.value, action.payload]
        },
        remove: (state, action) => {
            state.value = state.value.filter( el => el.id !== action.payload)
        },
        update: (state, action) => {
            state.value = state.value.map( el => el.id === action.payload.id ? {...el, ...action.payload} : el)
        },
        load: (state) => {
            state.loading = false
        }
    },
})

// Action creators are generated for each case reducer function
export const { set, add, remove, update, load } = datacfdiSlice.actions

export default datacfdiSlice.reducer