import { Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import axios from "axios";
import moment from "moment";
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { Suspense } from "react";
import { lazy } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSession } from "../Provider/Session";
import { updateDate, updateExpire, updateLicence, updateSkip } from "../Store/Feature/Main/Main";
import { AdminDashboard } from "./AdminDashboard";
import 'moment/locale/es-mx'
import { Checkbox } from "antd";
import { Buffer } from "buffer";

moment.locale('es_mx')

const GeneralDashboard = lazy( () => import('./GeneralDashboard'))
const RHDashboard = lazy( () => import('./RHDashboard'))

const LicenceDialog = forwardRef( (props, ref) => {
    const dispatch = useDispatch()
    const licenceDate = useSelector((state) => state.dataMain.licence)
    const [open, setOpen] = useState(false)
    const [check, setCheck] = useState(false)
    const [expire, setExpire] = useState(false)

    const handleClose = () => {
        setOpen(false)
        if (check){
            dispatch(updateSkip(true))
            localStorage.setItem('skip', true)
            localStorage.setItem('date', moment().format('YYYY-MM-DD'))
        }
    }

    useImperativeHandle(ref, () => ({
        open: () => setOpen(true),
        expireDate: () => setExpire(true)
    }))

    return (
        <Dialog open={open}>
            <DialogTitle>
                Aviso de vencimiento de licencia
            </DialogTitle>
            {
                !expire && (
                    <DialogContent>
                        <DialogContentText align="center" fontWeight='bold'>
                            Tu licencia de Workflow Manager expira el día <span>{moment(licenceDate).format('LL')}</span>.
                        </DialogContentText>
                        <DialogContentText sx={{ mt: 1, mb: 1 }} align="center">
                            Comunícate con el área de compras para la renovación de la licencia de uso
                            de la aplicación y puedas continuar disfrutando de una experiencia contínua
                            y sin interrupciones.
                        </DialogContentText>
                        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1, pt: 2 }}>
                            <Chip
                                color={ parseInt(moment(licenceDate).diff(moment(), 'days', true))+1 <= 15 ? 'error' : 'warning' }
                                label={`${parseInt(moment(licenceDate).diff(moment(), 'days', true))+1} días restantes`}
                            />
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1, pt: 2 }}>
                            <Checkbox checked={check} onChange={(e) => {setCheck(e.target.checked)}}>
                                No volver a mostrar por el día de hoy
                            </Checkbox>
                        </Box>
                    </DialogContent>
                )
            }
            {
                expire && (
                    <DialogContent>
                        <DialogContentText align="center" fontWeight='bold'>
                            Tu licencia de Workflow Manager expiró el día <span>{moment(licenceDate).format('LL')}</span>.
                        </DialogContentText>
                        <DialogContentText sx={{ mt: 1, mb: 1 }} align="center">
                            Comunícate con el área de compras para la renovación de la licencia de uso
                            de la aplicación y puedas continuar disfrutando de una experiencia contínua
                            y sin interrupciones.
                        </DialogContentText>
                    </DialogContent>
                )
            }
            <DialogActions>
                <Button color='error' onClick={handleClose}>
                    Cerrar
                </Button>
            </DialogActions>
        </Dialog>
    )
})

function MainDashboard(){
    const dispatch = useDispatch()
    const session = useSession()
    const licenceDialog = useRef()
    const skip = useSelector((state) => state.dataMain.skip)

    useEffect( () => {
        var skipDate = localStorage.getItem('date')
        if (localStorage.getItem('skip') && skipDate){
            if (moment().isAfter(skipDate, 'day')) {
                localStorage.removeItem('skip')
                localStorage.removeItem('date')
                dispatch(updateDate(moment().format('YYYY-MMM-DD')))
                dispatch(updateSkip(false))
            }
        }
    },[])

    useEffect( () => {
        if (!session.session.client && session.session.rol?.id === 1){
            axios.get(session.apiUrl() + 'data')
            .then( ({data}) => {
                var date = moment(data.data.licence)
                var diff = parseInt(date.diff(moment(), 'days', true))
                dispatch(updateLicence(data.data.licence))
                var base64 = Buffer.from(data.data.licence).toString('base64')
                localStorage.setItem('licence', base64)

                if (diff <= 30 && diff >= 0){
                    if (!skip) {
                        licenceDialog.current.open()
                    }
                }

                if (diff < 0){
                    licenceDialog.current.expireDate()
                    licenceDialog.current.open()
                    dispatch(updateExpire(true))
                }
            })
        }
    },[skip])

    const getDashboard = () => {
        if (session.session.client) return <GeneralDashboard/>
        if (session.session.rol.id === 1) return <AdminDashboard/>
        if (session.session.rol.id === 4) return <RHDashboard/>

        return <AdminDashboard/>
    }

    return (
        <Suspense>
            {getDashboard()}
            <LicenceDialog ref={licenceDialog} />
        </Suspense>
    )
}

export {MainDashboard}