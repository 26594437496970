import { createSlice } from '@reduxjs/toolkit'
import { Buffer } from 'buffer'
import moment from 'moment'

const initialState = {
    licence: localStorage.getItem('licence') ? Buffer.from(localStorage.getItem('licence'), 'base64').toString('ascii') : null,
    date: localStorage.getItem('date') ?? moment().format('YYYY-MM-DD'),
    skip: localStorage.getItem('skip') ?? false,
    expire: false
}

export const maincollection = createSlice({
    name: 'maincollection',
    initialState,
    reducers: {
        updateLicence: (state, action) => {
            state.licence = action.payload
        },
        updateDate: (state, action) => {
            state.date = action.payload
        },
        updateSkip: (state, action) => {
            state.skip = action.payload
        },
        updateExpire: (state, action) => {
            state.expire = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { updateLicence, updateDate, updateSkip, updateExpire } = maincollection.actions

export default maincollection.reducer