import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    client: null,
    branch: null,
    station: null,
    devswitch: null,
    nvr: null
}

export const devicecontrolSlice = createSlice({
    name: 'devicecontrol',
    initialState,
    reducers: {
        setClient: (state, action) => {
            state.client = action.payload
        },
        setBranch: (state, action) => {
            state.branch = action.payload
        },
        setStation: (state, action) => {
            state.station = action.payload
        },
        setSwitch: (state, action) => {
            state.devswitch = action.payload
        },
        setNvr: (state, action) => {
            state.nvr = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { setClient, setBranch, setStation, setSwitch, setNvr } = devicecontrolSlice.actions

export default devicecontrolSlice.reducer