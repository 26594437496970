import { ChevronLeft, Notifications, NotificationsActive } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import { 
    AppBar, Avatar, Backdrop, Badge, Box, Button, CircularProgress, Container, CssBaseline, 
    Divider, IconButton, List, ListItem, ListItemIcon, 
    ListItemText, Menu, MenuItem, SwipeableDrawer, Toolbar, Typography 
} from "@mui/material"
import { deepOrange } from '@mui/material/colors';
import React, { Suspense, useEffect, useRef, useState } from "react"
import { Outlet, useNavigate } from 'react-router-dom';
import { onMessageListener } from './firebase';
import { MenuList } from './MenuList';
import { MenuListClient } from './MenuListClient';
import { useSession } from './Provider/Session';
import NotificationSound from './Assets/notification_sound.mp3'
import { AiOutlinePoweroff } from 'react-icons/ai'
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { updateExpire, updateLicence } from './Store/Feature/Main/Main';
import { Buffer } from 'buffer';
import moment from 'moment';

if ('Notification' in window){
    let permission = Notification.permission;

    if(permission === "default" || permission === 'denied'){
        requestAndShowPermission();
    }
}

function requestAndShowPermission() {
    Notification.requestPermission(function (permission) {
    });
}

function NotificationItem(props){
	const notification = props.notification
	const navigation = useNavigate()

	const handleAction = () => {
		var action = notification.data.action

        props.close()
        if (action.includes('service')){
            navigation(`/service/${notification.data.id}`, { replace: true })
        }

        if (action.includes('project')){
            navigation(`/project/${notification.data.id}`, { replace: true })
        }

        if (action.includes('device')){
            navigation('/device')
        }
        return
	}

	return (
		<>
			<MenuItem onClick={handleAction} sx={{ whiteSpace: 'pre-wrap', maxWidth: 400 }} >
				<ListItemIcon>
					<Notifications/>
				</ListItemIcon>
				<ListItemText
					primary={notification.title}
					secondary={notification.body}
				/>
			</MenuItem>
		</>
	)
}

function Base(){
    const dispatch = useDispatch()
    const [open, setOpen] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)
    const openMenu = Boolean(anchorEl)
    const session = useSession()
    const [notificationList, setNotificationList] = useState([])
    const [notificationMenu, setNotificationMenu] = useState(null)
    const [waiting, setWaiting] = useState(false)
    const openNotification = Boolean(notificationMenu)
	const audio = useRef(null)

    onMessageListener().then(payload => {
        setNotificationList( (prevState) => (
			[ ...prevState, {title: payload.notification.title, body: payload.notification.body, id: payload.messageId, read: 0, data: payload.data} ]
		))
		audio.current.play()
	})

    const handleOpenNotification = (event) => {
        if (!notificationMenu){
            setNotificationMenu(event.currentTarget)
			setNotificationList( (prevState) => (
				prevState.map( item => ({...item, read: 1}) )
			))
        } else {
            setNotificationMenu(null)
        }
    }

	const closeNotification = () => {
		setNotificationMenu(null)
	}

    const toggleMenu = async () => {
        setOpen(!open)
    }

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const closeMenu = () => {
        setAnchorEl(null)
    }

    const handleKeyDown = (event) => {
        (event.ctrlKey && event.keyCode === 77) ?
            setOpen(!open) :
            setOpen(open)
    }

    const handleLogout = async () => {
        setWaiting(true)
        setAnchorEl(null)
        session.logout()
    }

    useEffect( () => {
        //getNotification()
        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    },[])

    useEffect(() => {
        var data = localStorage.getItem('licence')

            axios.get(session.apiUrl() + 'data')
            .then( ({data}) => {
                localStorage.setItem("licence", Buffer.from(data.data.licence).toString('base64'))
                dispatch(updateLicence(data.data.licence))

                var diff = moment(data.data.licence).isBefore(moment())
                if (diff){
                    console.log("header")
                    dispatch(updateExpire(true))
                } else {
                    console.log('No header')
                }
            })
    },[])

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
            <CssBaseline />
            <AppBar sx={{ bgcolor: '#000' }} position="sticky">
                <Toolbar>
                    <IconButton
                        onClick={toggleMenu}
                        sx={{color: '#fff', mr: 2}}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        component='h1'
                        variant='h6'
                        noWrap
                        sx={{ 
                            flexGrow: 1,
                            display: {
                                xs: 'none',
                                md: 'block'
                            }
                        }}
                    >
                        Workflow manager
                    </Typography>
                    <Typography
                        component='h1'
                        variant='h6'
                        noWrap
                        sx={{
                            flexGrow: 1,
                            display: {
                                xs: 'block',
                                md: 'none'
                            }
                        }}
                    >
                        WFM
                    </Typography>
                    <IconButton 
                        sx={{ color: '#fff', mr: 2 }}
                        aria-controls={openNotification ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={openNotification ? 'true' : undefined}
                        onClick={handleOpenNotification}
                    >
                        <Badge badgeContent={ notificationList.filter( el => el.read === 0).length } color='secondary'>
                            <Notifications />
                        </Badge>
                    </IconButton>
                    <Avatar
                        sx={{
                            bgcolor: deepOrange[500],
                            cursor: 'pointer',
                            width: 36,
                            height: 36
                        }}
                        onClick={handleMenu}
                    >
                        {
                            session.isClient() ?
                            session.session.name[0] :
                            session.session.name[0] + session.session.lastname[0]
                        }
                    </Avatar>
					<audio ref={audio} src={NotificationSound} />
                    {
                        openMenu && (
                            <Menu
                                anchorEl={anchorEl}
                                open={openMenu}
                                onClose={closeMenu}
                            >
                                <ListItem sx={{ outline: 'none' }}>
                                    <ListItemText
                                        primary='' secondary='Opciones'
                                    />
                                </ListItem>
                                <MenuItem onClick={handleLogout}>
                                    <ListItemIcon>
                                        <AiOutlinePoweroff />
                                    </ListItemIcon>
                                    <ListItemText primary='Cerrar sesión' />
                                </MenuItem>
                            </Menu>
                        )
                    }
                    {
                        openNotification && (
                            <Menu 
                                open={openNotification} 
                                anchorEl={notificationMenu} 
                                onClose={() => setNotificationMenu(null)}
                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                PaperProps={{
                                    elevation: 0,
                                    sx: {
                                      overflow: 'visible',
                                      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                      mt: 1.5,
                                      '&:before': {
                                        content: '""',
                                        display: 'block',
                                        position: 'absolute',
                                        top: 0,
                                        right: { xs: 65, sm: 14 },
                                        width: 10,
                                        height: 10,
                                        bgcolor: 'background.paper',
                                        transform: 'translateY(-50%) rotate(45deg)',
                                        zIndex: 0,
                                      },
                                    },
                                  }}
                            >
								{
									notificationList.map( item => (
										<NotificationItem key={item.id} notification={item} close={closeNotification} />
									))
								}
								{
									notificationList.length > 0 && (
										<ListItem sx={{ justifyContent: 'flex-end', mt: 1 }}>
											<Button onClick={() => {setNotificationList([])}} size='small'>
												Eliminar
											</Button>
										</ListItem>
									)
								}
								{
									notificationList.length === 0 && (
										<MenuItem sx={{ minWidth: { xs: '92vw', sm: 'auto' } }} >
											<Box sx={{ p: 1, display: 'flex', alignItem: 'center', justifyContent: 'center', width: '100%' }}>
												<NotificationsActive sx={{ mr: 1 }} />
												<Typography textAlign='center'>
													No tiene notificaciones nuevas
												</Typography>
											</Box>
										</MenuItem>
									)
								}
                            </Menu>
                        )
                    }
                </Toolbar>
            </AppBar>
            <SwipeableDrawer
                open={open}
                onOpen={toggleMenu}
                onClose={toggleMenu}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    px: [1]
                }}
            >
                <Toolbar
                    sx={{
                        display: 'flex',
                        flexDirection: 'row-reverse'
                    }}
                >
                    <IconButton onClick={toggleMenu}>
                        <ChevronLeft />
                    </IconButton>
                </Toolbar>
                <Divider />
                <List sx={{maxHeight: '90vh', overflowY: 'scroll', pr: 2, '&::-webkit-scrollbar': { display: 'none' }}} component='nav'>
                    {
                        !session.session.client && (
                            <MenuList toggle={setOpen} toggle2={toggleMenu} />
                        )
                    }
                    {
                        session.session.client && (
                            <MenuListClient toggle={setOpen} />
                        )
                    }
                </List>
            </SwipeableDrawer>
            <Box
                component='main'
                sx={{
                    p: 2,
                    pt: 1,
                    overflowY: 'scroll',
                    flexGrow: 1
                }}
            >
                <Container sx={{ p: 0, height: '100%' }} maxWidth='fixed'>
                    <Suspense>
                        <Outlet />
                    </Suspense>
                </Container>
            </Box>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={waiting}
                onClick={() => {setWaiting(false)}}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    )
}

export {Base}