import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    status: 1,
    search: '',
    client: null,
    branch: null,
    department: null,
    type: null,
    system: null
}

export const servicecontrolSlice = createSlice({
    name: 'servicecontrol',
    initialState,
    reducers: {
        setStatus: (state, action) => {
            state.status = action.payload
        },
        setSearch: (state, action) => {
            state.search = action.payload
        },
        setClient: (state, action) => {
            state.client = action.payload
        },
        setBranch: (state, action) => {
            state.branch = action.payload
        },
        setDepartment: (state, action) => {
            state.department = action.payload
        },
        setType: (state, action) => {
            state.type = action.payload
        },
        setSystem: (state, action) => {
            state.system = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { setStatus, setSearch, setClient, setBranch, setDepartment, setType, setSystem } = servicecontrolSlice.actions

export default servicecontrolSlice.reducer