import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    status: 1,
    type: 1,
    search: ''
}

export const usermainSlice = createSlice({
    name: 'usermain',
    initialState,
    reducers: {
        setStatus: (state, action) => {
            state.status = action.payload
        },
        setType: (state, action) => {
            state.type = action.payload
        },
        setSearch: (state, action) => {
            state.search = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { setStatus, setType, setSearch } = usermainSlice.actions

export default usermainSlice.reducer