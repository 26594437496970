import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    tab: 1,
    search: ''
}

export const miscSlice = createSlice({
    name: 'misc',
    initialState,
    reducers: {
        setTab: (state, action) => {
            state.tab = action.payload
        },
        setSearch: (state, action) => {
            state.search = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { setTab, setSearch } = miscSlice.actions

export default miscSlice.reducer