import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    client: null,
    branch: null,
    date: null,
    start: null,
    end: null,
    user: null,
    project: null,
    folio: null
}

export const bitacoracontrolSlice = createSlice({
    name: 'bitacoracontrol',
    initialState,
    reducers: {
        setClient: (state, action) => {
            state.client = action.payload
        },
        setBranch: (state, action) => {
            state.branch = action.payload
        },
        setDate: (state, action) => {
            state.date = action.payload
        },
        setStart: (state, action) => {
            state.start = action.payload
        },
        setEnd: (state, action) => {
            state.end = action.payload
        },
        setUser: (state, action) => {
            state.user = action.payload
        },
        setProject: (state, action) => {
            state.project = action.payload
        },
        setFolio: (state, action) => {
            state.folio = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { setClient, setBranch, setDate, setStart, setEnd, setUser, setProject, setFolio } = bitacoracontrolSlice.actions

export default bitacoracontrolSlice.reducer