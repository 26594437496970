import React, { Suspense } from "react";
import { Outlet } from "react-router-dom";

function Template(){

    return (
        <>
            <Suspense>
                <Outlet />
            </Suspense>
        </>
    )
}

export {Template}