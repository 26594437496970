import React, { lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useSession } from './Provider/Session'
import Login from './Login/Login'
import {Base} from "./Base";
import { Template } from "./Template";
import { MainDashboard } from "./Dashboard/MainDashboard";
import { Backdrop, CircularProgress } from "@mui/material";
import "primereact/resources/themes/lara-light-indigo/theme.css";  //theme
import "primereact/resources/primereact.min.css";                  //core css
import "primeicons/primeicons.css";                                //icons
import 'antd/dist/antd.css'
import { useSelector } from "react-redux";
 

const MainClient = lazy( () => import('./Client/MainClient'))
const MainUser = lazy( () => import('./User/MainUser'))
const MainDevice = lazy( () => import('./Device/MainDevice'))
const MainService = lazy( () => import('./Service/MainService'))
const MainProject = lazy( () => import('./Project/MainProject'))
const MainAttendance = lazy( () => import('./Attendance/MainAttendance'))
const MainSettings = lazy( () => import('./Settings/MainSettings'))
const MainBitacora = lazy( () => import('./Bitacora/MainBitacora'))
const MainCompras = lazy( () => import('./Compras/MainCompras'))
const MainVehicle = lazy( () => import('./Vehicle/MainVehicle'))
const MainInventory = lazy( () => import('./Inventory/MainInventory'))
const MainGantt = lazy( () => import('./Gantt/MainGantt'))

const Client = lazy( () => import('./Client/Client'))
const Service = lazy( () => import ('./Service/Service'))
const User = lazy( () => import('./User/User'))
const AddUser = lazy( () => import('./User/AddUser'))
const Project = lazy( () => import('./Project/Project'))
const Vehicle = lazy( () => import('./Vehicle/Vehicle'))
const ClientAttendance = lazy( () => import('./AttendanceClient/MainAttendance'));

function App() {
	const session = useSession()
	const permission = session.getPermission()
	const licence = useSelector((state) => state.dataMain.expire)

	return (
		<>
			<Suspense
				fallback={
					<Backdrop
						sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
						open={true}
					>
						<CircularProgress color="inherit" />
					</Backdrop>
				}
			>
			{
				session.session ?
					session.isClient() ?
						<Routes>
							<Route path='/' element={ <Base /> }>
								<Route index element={ <MainDashboard /> } />
								<Route path='service' element={ <Template/> }>
									<Route index element={ <MainService /> } />
									<Route path=':id' element={ <Service/> } />
								</Route>
								<Route path='device' element={ <Template/> }>
									<Route index element={ <MainDevice /> } />
								</Route>
								<Route path="project" element={ <Template /> } >
									<Route index element={ <MainProject/> } />
									<Route path=":id" element={ <Project/> } />
								</Route>
								<Route path='settings' element={ <Template/> }>
									<Route index element={ <MainSettings /> } />
								</Route>
								<Route path='client-attendance' element={ <Template />}>
									<Route index element={ <ClientAttendance/> } />
								</Route>
							</Route>
							<Route
								path="*"
								element={<Navigate to="/" replace />}
							/>
						</Routes>
						:
						<Routes>
							<Route path='/' element={ <Base /> }>
								<Route index element={ <MainDashboard /> } />
								{
									permission.CLIENT && (
										<Route path='client' element={ <Template/> }>
											<Route index element={ <MainClient /> } />
											<Route path=':id' element={ <Client /> } />
										</Route>
									)
								}
								{
									permission.SERVICE && (
										<Route path='service' element={ <Template/> }>
											<Route index element={ <MainService /> } />
											<Route path=':id' element={ <Service/> } />
										</Route>
									)
								}
								{
									permission.DEVICE && (
										<Route path='device' element={ <Template/> }>
											<Route index element={ <MainDevice /> } />
										</Route>
									)
								}
								{
									permission.BITACORA && (
										<Route path='bitacora' element={ <Template/> }>
											<Route index element={ <MainBitacora /> } />
										</Route>
									)
								}
								<Route path='compras' element={ <Template/> }>
									<Route index element={ <MainCompras /> } />
								</Route>
								{
									permission.VEHICLE && (
										<Route path='vehicle' element={ <Template/> }>
											<Route index element={ <MainVehicle /> } />
											<Route path=':id' element={ <Vehicle /> } />
										</Route>
									)
								}
								<Route path='inventory' element={ <Template/> }>
									<Route index element={ <MainInventory /> } />
								</Route>
								{
									permission.USER && (
										<Route path="user" element={ <Template /> } >
											<Route index element={ <MainUser /> } />
											<Route path='add' element={ <AddUser /> } />
											<Route path=':id' element={ <User /> } />
										</Route>
									)
								}
								{
									permission.PROJECT && (
										<Route path="project" element={ <Template /> } >
											<Route index element={ <MainProject/> } />
											<Route path=":id" element={ <Project/> } />
										</Route>
									)
								}
								{
									permission.ATTENDANCE && (
										<Route path='attendance' element={ <Template/> }>
											<Route index element={ <MainAttendance /> } />
										</Route>
									)
								}
								{
									(permission.SETTINGS && !licence) && (
										<Route path='settings' element={ <Template/> }>
											<Route index element={ <MainSettings /> } />
										</Route>
									)
								}
								{/*<Route path='password' element={ <Template /> }>
									<Route index element={ <MainRecovery /> } />
								</Route>*/}
							</Route>
							<Route
								path="*"
								element={<Navigate to="/" replace />}
							/>
						</Routes> 
				:
				<Routes>
					<Route index element={ <Login /> } />
					<Route path='gantt' element={ <MainGantt/> } />
					<Route
						path="*"
						element={<Navigate to="/" replace />}
					/>
				</Routes>
			}
			</Suspense>
		</>
	)
}

export default App;
