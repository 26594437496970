import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    status: 1,
    search: '',
    client: null,
    branch: null,
    department: null
}

export const projectcontrolSlice = createSlice({
    name: 'projectcontrol',
    initialState,
    reducers: {
        setStatus: (state, action) => {
            state.status = action.payload
        },
        setSearch: (state, action) => {
            state.search = action.payload
        },
        setClient: (state, action) => {
            state.client = action.payload
        },
        setBranch: (state, action) => {
            state.branch = action.payload
        },
        setDepartment: (state, action) => {
            state.department = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { setStatus, setSearch, setClient, setBranch, setDepartment } = projectcontrolSlice.actions

export default projectcontrolSlice.reducer