import { 
    AccountTree, AddCard, Business, DirectionsCar, FlagCircle, Group, 
    Handyman, Home, Inventory, Lan, PlaylistAddCheck, Settings
} from '@mui/icons-material'
import { Backdrop, CircularProgress, Divider, ListItemButton, ListItemIcon, ListItemText, ListSubheader } from '@mui/material'
import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useSession } from './Provider/Session'
import { useDispatch, useSelector } from 'react-redux'
import { setTab } from './Store/Misc'

export const MenuList = (props) => {
    const licenceExpire = useSelector((state) => state.dataMain.expire)
    const auth = useSession()
    const rol = auth.session.rol.id
    const permission = auth.getPermission()
    const [waiting, setWaiting] = useState(false)
    const dispatch = useDispatch()

    const closeMenu =  () => {
        props.toggle(false)
        dispatch(setTab(1))
    }

    return (
        <>
            <ListItemButton onClick={closeMenu} component={NavLink} to='/'>
                    <ListItemIcon>
                        <Home />
                    </ListItemIcon>
                <ListItemText primary="Inicio" />
            </ListItemButton>
            {
                permission.CLIENT && (
                    <ListItemButton onClick={closeMenu} component={NavLink} to='client'>
                        <ListItemIcon>
                            <Business />
                        </ListItemIcon>
                        <ListItemText primary='Cliente' />
                    </ListItemButton>
                )
            }
            {
                permission.PROJECT && (
                    <ListItemButton onClick={closeMenu} component={NavLink} to='project' >
                        <ListItemIcon>
                            <AccountTree />
                        </ListItemIcon>
                        <ListItemText primary='Proyecto' />
                    </ListItemButton>
                )
            }
            {
                permission.SERVICE && (
                    <ListItemButton onClick={closeMenu} component={NavLink} to='service'>
                        <ListItemIcon>
                            <Handyman />
                        </ListItemIcon>
                        <ListItemText primary='Servicio' />
                    </ListItemButton>
                )
            }
            {
                permission.DEVICE && (
                    <ListItemButton onClick={closeMenu} component={NavLink} to='device'>
                        <ListItemIcon>
                            <Lan />
                        </ListItemIcon>
                        <ListItemText primary='Dispositivos' />
                    </ListItemButton>
                )
            }
            {
                permission.BITACORA && (
                    <ListItemButton sx={{ display: { xs: 'none', sm: 'flex'} }} onClick={closeMenu} component={NavLink} to='bitacora' >
                        <ListItemIcon>
                            <FlagCircle />
                        </ListItemIcon>
                        <ListItemText primary='Bitácora' />
                    </ListItemButton>
                )
            }
            {
                permission.USER && (
                    <ListItemButton onClick={closeMenu} component={NavLink} to='user'>
                        <ListItemIcon>
                            <Group />
                        </ListItemIcon>
                        <ListItemText primary='Colaboradores' />
                    </ListItemButton>
                )
            }
            <Divider />
            <ListSubheader component='div'>
                Activos
            </ListSubheader>
            {
                permission.VEHICLE && (
                    <ListItemButton component={NavLink} to='vehicle' onClick={closeMenu}>
                        <ListItemIcon>
                            <DirectionsCar />
                        </ListItemIcon>
                        <ListItemText primary='Vehículo' />
                    </ListItemButton>
                )
            }
            <Divider />
            <ListSubheader component='div'>
                Reportes
            </ListSubheader>
            {
                permission.ATTENDANCE && (
                    <ListItemButton onClick={closeMenu} component={NavLink} to='attendance'>
                        <ListItemIcon>
                            <PlaylistAddCheck />
                        </ListItemIcon>
                        <ListItemText primary='Asistencia' />
                    </ListItemButton>
                )
            }
            <Divider />
            <ListSubheader component='div'>
                Administración
            </ListSubheader>
            {
                permission.SETTINGS && (
                    <ListItemButton disabled={licenceExpire} component={NavLink} to='settings' onClick={closeMenu}>
                        <ListItemIcon>
                            <Settings />
                        </ListItemIcon>
                        <ListItemText primary='Configuración' />
                    </ListItemButton>
                )
            }
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={waiting}
                onClick={() => {setWaiting(false)}}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    )
}
