import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value: [],
    loading: true
}

export const devicecollectionSlice = createSlice({
    name: 'devicecollection',
    initialState,
    reducers: {
        set: (state, action) => {
            state.value = action.payload
        },
        load: (state, action) => {
            state.loading = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { set, load } = devicecollectionSlice.actions

export default devicecollectionSlice.reducer