import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    status: 1,
    search: ''
}

export const vehiclecontrolSlice = createSlice({
    name: 'vehiclecontrol',
    initialState,
    reducers: {
        setStatus: (state, action) => {
            state.status = action.payload
        },
        setSearch: (state, action) => {
            state.search = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { setStatus, setSearch } = vehiclecontrolSlice.actions

export default vehiclecontrolSlice.reducer