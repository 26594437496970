// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

// Your web app's Firebase configuration
const firebaseConfig = {
	apiKey: "AIzaSyBl1-5ze5ESR08lJz00Ea36JilqKtp_FSI",
	authDomain: "isi-service.firebaseapp.com",
	projectId: "isi-service",
	storageBucket: "isi-service.appspot.com",
	messagingSenderId: "829715248885",
	appId: "1:829715248885:web:c235b3ee94313be71e37bc",
	measurementId: "G-V9NQC14BFY"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const fetchToken = (setTokenFound, setToken) => {
    return getToken(messaging, {vapidKey: 'BIsTF39FrQwIrifE9k6KKZrnYt3p18uHuYFiJjq84stZ_0ClV87Dyo8urJFvsTvWBVoyaBRFOJet7Xght3Q1Cu8'}).then((currentToken) => {
      	if (currentToken) {
        	setTokenFound(true);
			setToken(currentToken)
        	// Track the token -> client mapping, by sending to backend server
        	// show on the UI that permission is secured
      	} else {
        	setTokenFound(false);
        	// shows on the UI that permission is required 
      	}
    }).catch((err) => {
      	// catch error while creating client token
    });
  }
  
export const onMessageListener = () =>
    new Promise((resolve) => {
    	onMessage(messaging, (payload) => {
        	resolve(payload);
      	});
	});