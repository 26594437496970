import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    data: null,
    team: [],
    task: [],
    incidence: [],
    device: [],
    files: [],
    loading: true
}

export const dataProjectSlice = createSlice({
    name: 'dataProject',
    initialState,
    reducers: {
        setData: (state, action) => {
            state.data = action.payload
        },
        updateData: (state, action) => {
            state.data = {...state.data, ...action.payload}
        },
        // Files
        setFiles: (state, action) => {
            state.files = action.payload
        },
        addFiles: (state, action) => {
            state.files = [...state.files, action.payload]
        },
        updateFiles: (state, action) => {
            state.files = state.files.map( el => el.id === action.payload.id ? {...el, ...action.payload} : el)
        },
        removeFiles: (state, action) => {
            state.files = state.files.filter( el => el.id !== action.payload.id)
        },
        // Team
        setTeam: (state, action) => {
            state.team = action.payload
        },
        addTeam: (state, action) => {
            state.team = [...state.team, action.payload]
        },
        updateTeam: (state, action) => {
            state.team = state.team.map( el => el.id === action.payload.id ? {...el, ...action.payload} : el)
        },
        removeTeam: (state, action) => {
            state.team = state.team.filter( el => el.d !== action.payload)
        },
        // Task
        setTask: (state, action) => {
            state.task = action.payload
        },
        addTask: (state, action) => {
            state.task = [...state.task, action.payload]
        },
        updateTask: (state, action) => {
            state.task = state.task.map( el => el.id === action.payload.id ? {...el, ...action.payload} : el)
        },
        removeTask: (state, action) => {
            state.task = state.task.filter( el => el.id !== action.payload)
        },
        // Incidence
        setIncidence: (state, action) => {
            state.incidence = action.payload
        },
        addIncidence: (state, action) => {
            state.incidence = [...state.incidence, action.payload]
        },
        updateIncidence: (state, action) => {
            state.incidence = state.incidence.map( el => el.id === action.payload.id ? {...el, ...action.payload} : el)
        },
        removeIncidence: (state, action) => {
            state.incidence = state.incidence.filter( el => el.id !== action.payload)
        },
        // Device
        setDevice: (state, action) => {
            state.device = action.payload
        },
        addDevice: (state, action) => {
            state.device = [...state.device, action.payload]
        },
        updateDevice: (state, action) => {
            state.device = state.device.map( el => el.id === action.payload.id ? {...el, ...action.payload} : el)
        },
        removeDevice: (state, action) => {
            state.device = state.device.filter( el => el.id !== action.payload)
        },
        // load
        load: (state) => {
            state.loading = false
        }
    },
})

// Action creators are generated for each case reducer function
export const { 
    setData, updateData, load, setFiles, addFiles, updateFiles, removeFiles, setTeam, addTeam, updateTeam, removeTeam,
    setTask, addTask, updateTask, removeTask, setIncidence, addIncidence, updateIncidence, removeIncidence,
    setDevice, addDevice, updateDevice, removeDevice
} = dataProjectSlice.actions

export default dataProjectSlice.reducer