import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    date: null,
    user: '',
    tab: 0
}

export const attendancecontrolSlice = createSlice({
    name: 'attendancecontrol',
    initialState,
    reducers: {
        setDate: (state, action) => {
            state.date = action.payload
        },
        setUser: (state, action) => {
            state.user = action.payload
        },
        setTab: (state, action) => {
            state.tab = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { setDate, setUser, setTab } = attendancecontrolSlice.actions

export default attendancecontrolSlice.reducer