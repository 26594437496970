import { Alert, Avatar, Box, Grid, Paper, Snackbar, Typography } from "@mui/material";
import axios from "axios";
import React, { forwardRef, useEffect, useImperativeHandle, useLayoutEffect, useRef, useState } from "react";
import { useSession } from "../Provider/Session";
import { Chart } from 'primereact/chart'
import { useMemo } from "react";
import { Article, Group, Person, WorkHistory } from "@mui/icons-material";
import { blue } from "@mui/material/colors";
import randomcolor from 'randomcolor'

const months = [
    'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
]

const MonthChart = forwardRef( (props, ref) => {
    const [data, setData] = useState(null)
    const [dataset, setDataset] = useState({
        labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio'],
        datasets: [
            {
                label: 'Propuestas realizadas',
                data: [0,0,0,0,0,0],
                fill: false,
                borderColor: '#42A5F5',
                tension: .4
            },
            {
                label: 'Propuestas aprobadas',
                data: [0,0,0,0,0,0],
                fill: false,
                borderColor: '#FFA726',
                tension: .4
            }
        ]
    })

    useLayoutEffect(() => {
        var date = new Date().getMonth()
        var label = [ months.at(date-5), months.at(date-4), months.at(date-3), months.at(date-2), months.at(date-1), months.at(date) ]
        
        if (data){
            var set1 = [
                filterProposal(data.proposal, date-5).length,
                filterProposal(data.proposal, date-4).length,
                filterProposal(data.proposal, date-3).length,
                filterProposal(data.proposal, date-2).length,
                filterProposal(data.proposal, date-1).length,
                filterProposal(data.proposal, date).length
            ]
            var set2 = [
                filterProposal(data.project, date-5).length,
                filterProposal(data.project, date-4).length,
                filterProposal(data.project, date-3).length,
                filterProposal(data.project, date-2).length,
                filterProposal(data.project, date-1).length,
                filterProposal(data.project, date).length
            ]
    
            setDataset({
                labels: label,
                datasets: [
                    {
                        label: 'Propuestas realizadas',
                        data: set1,
                        fill: false,
                        borderColor: '#42A5F5',
                        tension: .4
                    },
                    {
                        label: 'Propuestas aprobadas',
                        data: set2,
                        fill: false,
                        borderColor: '#FFA726',
                        tension: .4
                    }
                ]
            })
        }
    },[data])

    const filterProposal = (data, month) => {
        return data.filter( el => {
            let date = new Date(el.createdAt).getMonth()
            return date === month
        })
    }

    const handleData = (item) => {
        setData(item)
    }

    useImperativeHandle( ref, () => ({
        setData: handleData
    }))

    return (
        <>
            <Paper elevation={2} sx={{ p: 2 }}>
                <Box sx={{ textAlign: 'center' }}>
                    <Typography sx={{ fontSize: 16, fontWeight: 'bold' }}>
                        Relación de propuesta - proyecto
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Chart width="80%" type="line" data={dataset} />
                </Box>
            </Paper>
        </>
    )
})

const PolarChart = forwardRef( (props, ref) => {
    const [project, setProject] = useState([])
    const [lightOptions] = useState({
        plugins: {
            legend: {
                labels: {
                    color: '#495057'
                }
            }
        },
        scales: {
            r: {
                grid: {
                    color: '#ebedef'
                },
                ticks: {
                    stepSize: 1
                }
            }
        }
    })

    const getData = () => {
        var month = new Date().getMonth() + 1
        var total_process = project.filter( el => {
            if (!el.inProcess) return false
            var [year, inProcess] = el.inProcess?.split('-')
            return ( month === parseInt(inProcess) )
        })
        var total_start = project.filter( el => {
            if (!el.start) return false
            var [year, start] = el.start?.split('-')
            return ( month === parseInt(start) )
        })
        var total_deliver = project.filter( el => {
            if (!el.inDeliver) return false
            var [year, inDeliver] = el.inDeliver?.split('-')
            return ( month === parseInt(inDeliver) )
        })
        var total_invoice = project.filter( el => {
            if (!el.inInvoice) return false
            var [year, inInvoice] = el.inInvoice?.split('-')
            return ( month === parseInt(inInvoice) )
        })
        var total_end = project.filter( el => {
            if (!el.ended) return false
            var [year, ended] = el.ended.split('-')
            return ( month === parseInt(ended) )
        })

        var data = {
            datasets: [{
                data: [
                    total_process.length, total_start.length, total_deliver.length, total_invoice.length, total_end.length
                ],
                backgroundColor: [
                    "rgba(66, 165, 245, 0.5)",
                    "rgba(102, 187, 106, 0.5)", 
                    "rgba(255, 167, 38, 0.5)", 
                    "rgba(38, 198, 218, 0.5)", 
                    "rgba(126, 87, 194, 0.5)"
                ],
                label: 'Cantidad de proyectos del mes'
            }],
            labels: [
                "Aprobados", "Iniciados", "Entrega", "Facturación", "Finalizado"
            ]
        }

        return data
    }

    const dataset = useMemo( () => getData(), [project])

    const setData = (item) => {
        setProject(item)
    }

    useImperativeHandle(ref, () => ({
        setData: setData
    }))

    return (
        <>
            <Paper elevation={2} sx={{ p: 2 }}>
                <Box sx={{ textAlign: 'center' }}>
                    <Typography sx={{ fontSize: 16, fontWeight: 'bold' }}>
                        Reporte mensual de estado de proyectos
                    </Typography>
                </Box>
                <Chart type="polarArea" data={dataset} options={lightOptions} />
            </Paper>
        </>
    )
})

const ServiceCharts = forwardRef( (props, ref) => {
    const session = useSession()
    var controller = new AbortController()
    const [service, setService] = useState([])
    const [type, setType] = useState([])
    const [system, setSystem] = useState([])
    const [radarOptions] = useState({
        scales: {
            r: {
                beginAtZero: true
            }
        }
    })

    useEffect( () => {
        Promise.all([ getType(), getSystem() ])
        .then( (result) => {
            setType(result[0].data.data)
            setSystem(result[1].data.data)
        })
        .catch( (error) => {})

        return () => {
            controller.abort()
            setType(null)
            setSystem(null)
        }
    },[])

    const getType = () => {
        return axios.get(session.apiUrl() + 'data/typeservice', {
            params: {
                active: true
            },
            signal: controller.signal
        })
    }

    const getSystem = () => {
        return axios.get(session.apiUrl() + 'data/systemservice', {
            params: {
                active: true
            },
            signal: controller.signal
        })
    }

    const getData = () => {
        var ids = type.map( el => el.id)
        var label = type.map( el => el.name)
        var background = randomcolor({ count: ids.length })

        var data = []
        ids.forEach( (el, index) => {
            var tmp = service.filter( item => item.type === el)
            data.push(tmp.length)
        })

        var dataset = {
            labels: label,
            datasets: [{ data: data, backgroundColor: background }]
        }
        
        return dataset
    }

    const dataset = useMemo( () => getData(), [service])

    const getData2 = () => {
        var ids = system.map( el => el.id)
        var label = system.map( el => el.name)

        var data = []
        ids.forEach( (el, index) => {
            var tmp = service.filter( item => item.system === el)
            data.push( (tmp.length / service.length) * 100 )
        })

        var dataset = {
            labels: label,
            datasets: [{
                label: 'Porcentaje de sistemas con fallas',
                backgroundColor: 'rgba(179,181,198,0.2)',
                borderColor: 'rgba(179,181,198,1)',
                pointBackgroundColor: 'rgba(179,181,198,1)',
                pointBorderColor: '#fff',
                pointHoverBackgroundColor: '#fff',
                pointHoverBorderColor: 'rgba(179,181,198,1)',
                data: data
            }]
        }
        
        return dataset
    }

    const dataset2 = useMemo( () => getData2(), [service])

    const setData = (item) => {
        setService(item)
    }

    useImperativeHandle( ref, () => ({
        setData: setData
    }))

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <Paper elevation={2} sx={{ p: 2 }}>
                        <Box sx={{ textAlign: 'center' }}>
                            <Typography sx={{ fontSize: 16, fontWeight: 'bold' }}>
                                Reporte mensual de servicios
                            </Typography>
                        </Box>
                        <Chart type="doughnut" data={dataset} />
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Paper elevation={2} sx={{ p: 2 }}>
                        <Box sx={{ textAlign: 'center' }}>
                            <Typography sx={{ fontSize: 16, fontWeight: 'bold' }}>
                                Porcentaje mensual de sistemas con fallas
                            </Typography>
                        </Box>
                        <Chart type="radar" data={dataset2} options={radarOptions} />
                    </Paper>
                </Grid>
            </Grid>
        </>
    )
})

function AdminDashboard(){
    const session = useSession()
    const [data, setData] = useState([])
    const polarRef = useRef()
    const serviceRef = useRef()
    const [snackbar, setSnackbar] = useState({
        open: false, severity: 'success', message: ''
    })

    const closeSnackbar = () => {
        setSnackbar({
            open: false, severity: 'success', message: ''
        })
    }

    useEffect( () => {
        var controller = new AbortController()
        var date = new Date().getMonth() + 1
        axios.get(session.apiUrl() + 'dashboard', {
            params: {
                admin: true,
                month: date
            },
            signal: controller.signal
        })
        .then( ({data}) => {
            setData(data.data)
            polarRef.current.setData(data.data.projectdata)
            serviceRef.current.setData(data.data.servicedata)
        })
        .catch( (error) => {
            setSnackbar({
                open: true, severity: 'error', message: 'No han podido cargar los datos, inténtelo más tarde'
            })
        })

        return () => {
            controller.abort()
            setData(null)
            polarRef.current = false
            serviceRef.current = false
        }
    }, [])

    return (
        <>
            <Box sx={{ p: 1.5 }}>
                <Typography sx={{ fontSize: { xs: 16, sm: 18 }, fontWeight: 'bold' }}>
                    { `Bienvenido ${session.session.name} ${session.session.lastname}` }
                </Typography>
            </Box>
            <Grid sx={{ mb: 3 }} container spacing={2}>
                {
                    !session.session.client && (
                        <Grid item xs={6} sm={2}>
                            <Paper sx={{ p: 2 }} elevation={3}>
                                <Grid container spacing={2}>
                                    <Grid sx={{ display: 'flex', flexDirection: 'column' }} item xs={8.5}>
                                        <Typography color='text.secondary'>
                                            Prospectos
                                        </Typography>
                                        <Typography variant='h4'>
                                            {data.prospect}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3.5}>
                                        <Avatar sx={{ bgcolor: blue[500], width: { xs: 30, sm: 40 }, height: { xs: 30, sm: 40 } }} >
                                            <Person />
                                        </Avatar>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    )
                }
                {
                    !session.session.client && (
                        <Grid item xs={6} sm={2}>
                            <Paper sx={{ p: 2 }} elevation={3}>
                                <Grid container spacing={2}>
                                    <Grid sx={{ display: 'flex', flexDirection: 'column' }} item xs={8.5}>
                                        <Typography color='text.secondary'>
                                            Clientes
                                        </Typography>
                                        <Typography variant='h4'>
                                            {data.client}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3.5}>
                                        <Avatar sx={{ bgcolor: blue[500], width: { xs: 30, sm: 40 }, height: { xs: 30, sm: 40 } }} >
                                            <Group />
                                        </Avatar>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    )
                }
                <Grid item xs={6} sm={2}>
                    <Paper sx={{ p: 2 }} elevation={3}>
                        <Grid container spacing={2}>
                            <Grid sx={{ display: 'flex', flexDirection: 'column' }} item xs={8.5}>
                                <Typography color='text.secondary'>
                                    Propuestas
                                </Typography>
                                <Typography variant='h4'>
                                    {data.proposal}
                                </Typography>
                            </Grid>
                            <Grid item xs={3.5}>
                                <Avatar sx={{ bgcolor: blue[500], width: { xs: 30, sm: 40 }, height: { xs: 30, sm: 40 } }} >
                                    <Article />
                                </Avatar>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={6} sm={2}>
                    <Paper sx={{ p: 2 }} elevation={3}>
                        <Grid container spacing={2}>
                            <Grid sx={{ display: 'flex', flexDirection: 'column' }} item xs={8.5}>
                                <Typography color='text.secondary'>
                                    Proyectos
                                </Typography>
                                <Typography variant='h4'>
                                    {data.project}
                                </Typography>
                            </Grid>
                            <Grid item xs={3.5}>
                                <Avatar sx={{ bgcolor: blue[500], width: { xs: 30, sm: 40 }, height: { xs: 30, sm: 40 } }} >
                                    <WorkHistory />
                                </Avatar>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                    <PolarChart ref={polarRef} />
                </Grid>
                <Grid item xs={12} sm={8}>
                    <ServiceCharts ref={serviceRef} />
                </Grid>
                {/*<Grid item xs={12}>
                    <MonthChart ref={monthRef} />
                </Grid>*/}
            </Grid>
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={closeSnackbar}
            >
                <Alert severity={Snackbar.severity} >{snackbar.message}</Alert>
            </Snackbar>
        </>
    )
}

export {AdminDashboard}