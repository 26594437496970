import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    data: null,
    team: [],
    task: [],
    incidence: [],
    deviceStation: [],
    deviceSwitch: [],
    deviceNVR: [],
    deviceEndpoint: [],
    files: [],
    loading: true
}

export const dataServiceSlice = createSlice({
    name: 'dataService',
    initialState,
    reducers: {
        setData: (state, action) => {
            state.data = action.payload
        },
        updateData: (state, action) => {
            state.data = {...state.data, ...action.payload}
        },
        // Files
        setFiles: (state, action) => {
            state.files = action.payload
        },
        addFiles: (state, action) => {
            state.files = [...state.files, action.payload]
        },
        updateFiles: (state, action) => {
            state.files = state.files.map( el => el.id === action.payload.id ? {...el, files: [...el.files, ...action.payload.files]} : el)
        },
        removeFiles: (state, action) => {
            state.files = state.files.map( el => el.id !== action.payload.id ? 
                {...el, files: el.files.filter( (item, i) => {
                    console.log('index', i)
                    return i !== action.payload.index
            }) } : el
            )
        },
        // Team
        setTeam: (state, action) => {
            state.team = action.payload
        },
        addTeam: (state, action) => {
            state.team = [...state.team, action.payload]
        },
        updateTeam: (state, action) => {
            state.team = state.team.map( el => el.id === action.payload.id ? {...el, ...action.payload} : el)
        },
        removeTeam: (state, action) => {
            state.team = state.team.filter( el => el.id !== action.payload)
        },
        // Task
        setTask: (state, action) => {
            state.task = action.payload
        },
        addTask: (state, action) => {
            state.task = [...state.task, action.payload]
        },
        updateTask: (state, action) => {
            console.log('state', action)
            state.task = state.task.map( el => el.id === action.payload.id ? {...el, ...action.payload} : el)
        },
        removeTask: (state, action) => {
            console.log(action)
            state.task = state.task.filter( el => el.id !== action.payload)
        },
        // Incidence
        setIncidence: (state, action) => {
            state.incidence = action.payload
        },
        addIncidence: (state, action) => {
            state.incidence = [...state.incidence, action.payload]
        },
        updateIncidence: (state, action) => {
            state.incidence = state.incidence.map( el => el.id === action.payload.id ? {...el, ...action.payload} : el)
        },
        removeIncidence: (state, action) => {
            state.incidence = state.incidence.filter( el => el.id !== action.payload)
        },
        // Device
        setDevice: (state, action) => {
            state.deviceStation = action.payload.station
            state.deviceSwitch = action.payload.switch
            state.deviceNVR = action.payload.nvr
            state.deviceEndpoint = action.payload.endpoint
        },
        addStation: (state, action) => {
            state.deviceStation = [...state.deviceStation, action.payload]
        },
        addSwitch: (state, action) => {
            state.deviceSwitch = [...state.deviceSwitch, action.payload]
        },
        addNVR: (state, action) => {
            state.deviceNVR = [...state.deviceNVR, action.payload]
        },
        addEndpoint: (state, action) => {
            state.deviceEndpoint = [...state.deviceEndpoint, action.payload]
        },
        updateStation: (state, action) => {
            state.deviceStation = state.deviceStation.map( el => el.id === action.payload.id ? {...el, ...action.payload} : el )
        },
        updateSwitch: (state, action) => {
            state.deviceSwitch = state.deviceSwitch.map( el => el.id === action.payload.id ? {...el, ...action.payload} : el )
        },
        updateNVR: (state, action) => {
            state.deviceNVR = state.deviceNVR.map( el => el.id === action.payload.id ? {...el, ...action.payload} : el )
        },
        updateEndpoint: (state, action) => {
            state.deviceEndpoint = state.deviceEndpoint.map( el => el.id === action.payload.id ? {...el, ...action.payload} : el )
        },
        removeStation: (state, action) => {
            state.deviceStation = state.deviceStation.filter( el => el.id !== action.payload)
        },
        removeSwitch: (state, action) => {
            state.deviceSwitch = state.deviceSwitch.filter( el => el.id !== action.payload)
        },
        removeNVR: (state, action) => {
            state.deviceNVR = state.deviceNVR.filter( el => el.id !== action.payload)
        },
        removeEndpoint: (state, action) => {
            state.deviceEndpoint = state.deviceEndpoint.filter( el => el.id !== action.payload)
        },
        // load
        load: (state) => {
            state.loading = false
        }
    },
})

// Action creators are generated for each case reducer function
export const { 
    setData, updateData, load, setFiles, addFiles, updateFiles, removeFiles, setTeam, addTeam, updateTeam, removeTeam,
    setTask, addTask, updateTask, removeTask, setIncidence, addIncidence, updateIncidence, removeIncidence,
    setDevice, addStation, addSwitch, addNVR, addEndpoint, updateStation, updateNVR, updateSwitch, updateEndpoint,
    removeStation, removeSwitch, removeNVR, removeEndpoint
} = dataServiceSlice.actions

export default dataServiceSlice.reducer