import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value: [],
    loading: true
}

export const projectcollectionSlice = createSlice({
    name: 'projectcollection',
    initialState,
    reducers: {
        set: (state, action) => {
            state.value = action.payload
        },
        add: (state, action) => {
            state.value = [...state.value, action.payload]
        },
        remove: (state, action) => {
            state.value = state.value.filter( el => el.id !== action.payload)
        },
        update: (state, action) => {
            if (state.value.some( el => el.id === action.payload.id )){
                state.value = state.value.map( el => el.id === action.payload.id ? {...el, ...action.payload} : el)
            } else {
                state.value = [...state.value, action.payload]
            }
        },
        load: (state) => {
            state.loading = false
        }
    },
})

// Action creators are generated for each case reducer function
export const { set, add, remove, update, load } = projectcollectionSlice.actions

export default projectcollectionSlice.reducer