import { AccountTree, Handyman, Home, Lan, PlaylistAddCheck } from '@mui/icons-material'
import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import React from 'react'
import { NavLink } from 'react-router-dom'

export const MenuListClient = (props) => {

    const closeMenu =  () => {
        props.toggle(false)
    }

    return (
        <>
            <ListItemButton onClick={closeMenu} component={NavLink} to='/'>
                    <ListItemIcon>
                        <Home />
                    </ListItemIcon>
                <ListItemText primary="Inicio" />
            </ListItemButton>

            <ListItemButton component={NavLink} to='project' onClick={closeMenu}>
                <ListItemIcon>
                    <AccountTree />
                </ListItemIcon>
                <ListItemText primary='Proyecto' />
            </ListItemButton>

            <ListItemButton component={NavLink} to='service' onClick={closeMenu}>
                <ListItemIcon>
                    <Handyman />
                </ListItemIcon>
                <ListItemText primary='Servicio' />
            </ListItemButton>

            <ListItemButton component={NavLink} to='device' onClick={closeMenu}>
                <ListItemIcon>
                    <Lan />
                </ListItemIcon>
                <ListItemText primary='Dispositivos' />
            </ListItemButton>

            <ListItemButton component={NavLink} to='client-attendance' onClick={closeMenu}>
                <ListItemIcon>
                    <PlaylistAddCheck />
                </ListItemIcon>
                <ListItemText primary="Asistencia" />
            </ListItemButton>
        </>
    )
}
